import { Image } from "react-bootstrap";
import "./about-home.css";
import aboutus from "../../images/home/aboutus.png";

function HomeAbout() {
  return (
    <>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "50px",
          fontWeight: "600",
          fontSize: "3rem",
        }}
      >
        About Us
      </h1>
      <section style={{ backgroundColor: "rgb(129, 194, 255)" }}>
        <div className="container">
          <div className="about-home">
            <div className="row">
              <div
                className="col-lg-6"
                style={{
                  paddingTop: "35px",
                  paddingBottom: "35px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <h1 style={{ fontSize: "2.75rem" }}>
                    Transforming Health Through Nature's Power
                  </h1>
                  <p>
                    Saanso Nutri Sciences is a culmination of Saanso Life
                    Sciences and Med C Pharma to bring in clinically effective
                    Medical Nutrition to the people facing chronic health
                    challenges.
                    <br /> Med C Pharma has been involved in developing
                    clinically proven nutrition products and is a pioneer in
                    Diabetic Nutrition with 10+ yrs of expertise in this field.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="home-about-bg-img">
                  <Image src={aboutus} alt="" />
                  <button className="top-right-btn" disabled>
                    35+
                    <br />
                    Benefits
                  </button>
                  <button className="bottom-left-btn" disabled>
                    5+
                    <br />
                    Available Products
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default HomeAbout;
