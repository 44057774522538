import Footer from "./Footer/Footer";
import Header from "./Header/Header";
// import Business from "./Homepage/Business/Business";
import Banner from "./Homepage/HomeBanner/Banner";
import HomeAbout from "./Homepage/HomePageAbout/HomeAbout";
import Products from "./Homepage/HomeProducts/Products";
// import Nutrition from "./Homepage/NutritionBenefits/Nutrition";
import Features from "./Homepage/ProductFeatures/Features";
import Testmonials from "./Homepage/Testmonials/Testmonials";

function HomePage() {
  return (
    <>
      <div className="floating-menu">
        <Header />
      </div>
      <Banner />
      <Features />
      <Products />
      <HomeAbout />
      {/* <Nutrition />
      <Business /> */}
      <Testmonials />
      <Footer />
    </>
  );
}
export default HomePage;
