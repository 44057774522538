import "./App.css";
import { Switch, Route } from "react-router-dom/cjs/react-router-dom.min";

import HomePage from "./HomePage";
import Product1 from "./Product1";
import Product2 from "./Product2";
import Product3 from "./Product3";
// import Product4 from "./Product4";
import Contact from "./Contact";
import AboutUs from "./AboutUs";
import FullScreenVideo from "./Homepage/HomeVideo/HomeVideo";

function App() {
  return (
    <>
      <Switch>
        <Route path="/" exact>
          <FullScreenVideo />
        </Route>
        <Route path="/home" exact>
          <HomePage />
        </Route>
        <Route path="/saansure" exact>
          <Product1 />
        </Route>
        <Route path="/obeat" exact>
          <Product2 />
        </Route>
        <Route path="/cogni-strong" exact>
          <Product3 />
        </Route>
        {/*<Route path="/product4" exact>
          <Product4 />
        </Route> */}
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path="/about-us" exact>
          <AboutUs />
        </Route>
      </Switch>
    </>
  );
}

export default App;
