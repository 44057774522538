import { Image } from "react-bootstrap";
import "./product.css";
function ProductDescription({ pdcontent }) {
  return (
    <>
      <section className="pd-powder">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image src={pdcontent.pdimg} alt="" fluid />
            </div>
            <div className="col-lg-7">
              <h1
                style={{ color: "green", fontWeight: "700", fontSize: "3rem" }}
              >
                {pdcontent.pdh1}
              </h1>
              <h1
                style={{ color: "grey", fontWeight: "600", fontSize: "3rem" }}
              >
                {pdcontent.pdh12}{" "}
              </h1>
              <sapn className="horizontal-line" />
              <h3>Description</h3>
              <p>{pdcontent.pdpara1}</p>
              <p>{pdcontent.pdpara2}</p>
              <h3>Composition</h3>
              {pdcontent.pdcomp.map((comp) => (
                <p key={comp}>{comp}</p>
              ))}
              {/* <p>{pdcontent.pdcomp}</p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ProductDescription;
