import { Image } from "react-bootstrap";
import logo from "../images/logo/logo.png";
import "./footer.css";

function Footer() {
  return (
    <>
      <section className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 footer-logo">
              <Image src={logo} alt="" fluid />
              <h1 className="f-h1">Grow up your Healthy Fitness with Herbs</h1>
            </div>
            <div className="col-lg-2 footer-links">
              <h2>Links</h2>
              <a href="/home">Home</a>
              <a href="/about-us">About</a>
              <a href="/contact">Contact</a>
            </div>
            <div className="col-lg-2 footer-links">
              <h2>Our Products</h2>
              <a href="/saansure">Saansure</a>
              <a href="/obeat">Obeat</a>
              <a href="/cogni-strong">Cogni Strong</a>
            </div>
            {/* <div className="col-lg-2 footer-links">
              <h2>Support</h2>
              <a href="/#">Privacy Policy</a>
              <a href="/#">Return Policy</a>
              <a href="/#">Helps & FAQ's</a>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
export default Footer;
