import Header from "./Header/Header";
import Cta from "./Products/Cta";
import ProductBanner from "./Products/ProductBanner";
import ProductDescription from "./Products/ProductDescription";
// import ProductIndication from "./Products/ProductIndication";
import RelatedProducts from "./Products/RelatedProducts";
import "./App.css";
import Footer from "./Footer/Footer";
import videobanner from "./images/obeat/Obeatb.png";

import { Image } from "react-bootstrap";
import "./Homepage/NutritionBenefits/nutrition.css";
import Accordion from "react-bootstrap/Accordion";
import productimg from "./images/ProductBanners/product2.png";
import obeat from "./images/obeat/obeat.png";
import weight from "./images/obeat/weight.png";
import obeatv from "./images/obeat/obeatv.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import pimg1 from "./images/home/1.png";
import pimg2 from "./images/home/2.png";

const content = {
  bhead: "A Nutrient-Packed, Low-Calorie Diet Solution for Weight Management",
  bimg: productimg,
};

const pdcontent = {
  pdimg: obeat,
  pdh1: "Obeat",
  pdh12: "Healthy Weight Management with Every Serving",
  pdpara1:
    "Obeat is more than a typical low-calorie food—it’s a nutrient-dense, plant-based solution designed to help you manage your weight without sacrificing essential nutrients. Made with fibre-rich whole grains, protein-packed pulses, and antioxidant-rich ingredients, Obeat provides the nutrition you need to feel satisfied and energised while keeping calories low. With Obeat, achieving your weight goals feels natural, sustainable, and effective.",
  pdpara2: "",
  pdcomp: [
    "Millets and cereals 41%(Pearl Barley,Ragi,Jowar), Pulses 11% (Bengal gram, Black gram,Green gram), Dry fruits, Nuts& seeds 21%(Almonds,Cashew, Raisins, Chia seeds, Flax seeds, Water melon seeds, Pumpkin seeds, Sunflower seeds), Dehydrated Vegetables 6%(Carrot, Tomato) Jaggery, Rock Salt, Tamarind, Gingelly oil, Groundnut oil, Ginger, Garlic, Chillies.",
    "CONTAINS ADDED NATURAL FLAVOUR (MIXED MASALA).",
  ],
};

// const pdIndications = {
//   t1: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t2: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t3: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t4: "Ornare vestibulum aenean nullam nulla pretium tellus.",
// };
const relatedimages = {
  img1: pimg1,
  img2: pimg2,
  img3: "https://dummyjson.com/image/500x550",
};

function Product2() {
  return (
    <>
      <Header />
      <ProductBanner content={content} />
      <ProductDescription pdcontent={pdcontent} />
      {/* <ProductIndication indications={pdIndications} /> */}
      <section style={{ marginTop: "75px", marginBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <p
                  style={{
                    fontSize: "0.75rem",
                    color: "lightgreen",
                    marginBottom: "0px",
                    fontWeight: "600",
                  }}
                >
                  Key Benefits of Obeat
                </p>
                <h2>Supports Weight Management</h2>
                <p>
                  Low-calorie yet high in fibre and protein, Obeat keeps you
                  satisfied for longer, helping you manage your weight without
                  feeling deprived.
                </p>
                <h2>Boosts Immunity</h2>
                <p>
                  With plant-based proteins and antioxidants, Obeat supports
                  your body’s natural defences, making it easier to stay healthy
                  and strong.
                </p>
                <h2>Promotes Digestive Health</h2>
                <p>
                  The high fibre content in Obeat aids digestion and supports
                  gut health, reducing bloating and discomfort for a lighter,
                  healthier feeling.
                </p>
                <h2>Balanced Nutrition for Sustained Energy</h2>
                <p>
                  Obeat is designed to provide steady energy throughout the day,
                  preventing mid-day crashes and supporting an active lifestyle.
                </p>
                {/* <a href="#">
                  <button
                    style={{
                      width: "150px",
                      height: "40px",
                      border: "none",
                      borderRadius: "20px",
                      backgroundColor: "lightgreen",
                      color: "white",
                    }}
                  >
                    Know More
                  </button>
                </a> */}
              </div>
            </div>
            <div className="col-lg-6">
              <Image src={weight} alt="" fluid />
            </div>
          </div>
        </div>
      </section>
      <section style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "6vh",
            color: "rgb(108, 184, 255)",
            fontWeight: "600",
          }}
        >
          All about products
        </h1>

        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "6vh",
            color: "rgb(108, 184, 255)",
            fontWeight: "600",
          }}
        >
          is nutrition
        </h1>
        <span style={{ paddingTop: "40px" }} />
        <div className="container">
          <div className="row ">
            <div className="col-lg-3 nut-sec-1">
              <div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Fiber-Rich to Keep You Full Longer
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia{" "}
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Sustainable Energy Without the Sugar Crash
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Heart-Healthy Fats for Balanced Nutrition
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <h2 className="nutri-p">
                  Plant-Based Protein to
                  <br /> Build Lean Muscle
                </h2>
                {/* <p className="nutri-p">
                  Ridiculus sceleque phasellus interdum potenti velitcubilia
                </p> */}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Image
                  src={obeatv}
                  alt=""
                  fluid
                  style={{ display: "flex", justifyContent: "center" }}
                />
              </div>
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <h2 className="nutri-p">
                  Antioxidants to Boost Immunity
                  <br /> as You Lose Weight
                </h2>
                {/* <p className="nutri-p">
                  Ridiculus sceleque phasellus interdum potenti velitcubilia
                </p> */}
              </div>
            </div>
            <div className="col-lg-3 nut-sec-1">
              <div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Omega-3s to Support Brain Health & Emotional
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia{" "}
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Vitamins A & E for Healthy Skin During Weight Loss
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Need text here</h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="container"
          style={{
            marginTop: "75px",
            marginBottom: "75px",
          }}
        >
          <div className="row">
            <div className="col-lg-12">
              <h2>Usage and Dosage Instructions</h2>
              <p>
                Consume one sachet of Obeat as a light meal replacement or
                healthy snack option, ideal for breakfast or dinner.
              </p>
              <p>
                Mix Obeat into smoothies, pair with yoghurt, or enjoy with warm
                water as a wholesome, low-calorie meal that fits easily into
                your routine.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div classname="container">
          <div
            className="row"
            style={{
              padding: "15px",
              marginLeft: "10px",
              marginRight: "10px",
              height: "450px",
              backgroundImage: `url(${videobanner})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  console.log("hello");
                }}
              >
                <FontAwesomeIcon
                  icon={faPlay}
                  size="3x"
                  style={{ marginLeft: "7px" }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <RelatedProducts relatedimgs={relatedimages} />
      <section>
        <div className="container">
          <div className="row">
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "60px",
                paddingBottom: "60px",
              }}
            >
              Frequently Asked Questions
            </h1>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>1.When to consume? </Accordion.Header>
                <Accordion.Body>
                  This can be consumed like breakfast or as partial meal
                  replacement during dinner.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2.Can it cut down my calorie intake?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, it is highly satiating food which when consumed would cut
                  down hunger pangs and thus the number of calories consumed per
                  day.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. What's the impact of satiating foods on weight?
                </Accordion.Header>
                <Accordion.Body>
                  Satiating foods would reduce calorie intake which would have
                  significant impact on weight when it's combined with physical
                  activity .{" "}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
      {/* <Cta /> */}
      <Footer />
    </>
  );
}
export default Product2;
