// import Image from "react-bootstrap/Image";
import "./banner.css";
// import home1 from "../../images/home/home1.png";
import home1 from "../../images/home/homebanner.mp4";

function Banner() {
  return (
    <>
      <section style={{ paddingTop: "95px", paddingBottom: "75px" }}>
        <div className="video-container">
          <video autoPlay loop muted className="background-video">
            <source src={home1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="overlay">
            <h2>
              Transforming Lives through evidence based solutions in Medical
              Nutrition Therapy.
            </h2>
            {/* <p>
              As a Pioneer in the science of nutrition Therapy, every product of
              ours is rooted in science and rigorously tested to deliver the
              right results.
            </p>
            <p>
              We provide a wide range of nutrition therapies to help you boost
              good health and improve the overall Well-being. We believe that
              the right nutrition can make a difference in your life, regardless
              of your motivation.
            </p> */}
          </div>
        </div>

        {/* <div className="container">
          <div className="row">
            <div className="col-lg-6 content">
              <p className="title">Saanso Nutri</p>
              <h1 className="heading">
                Transforming Lives through evidence based solutions in Medical
                Nutrition Therapy.
              </h1>
              <br />
              <p className="context">
                As a Pioneer in the science of nutrition Therapy, every product
                of ours is rooted in science and rigorously tested to deliver
                the right results.
                <br /> We provide a wide range of nutrition therapies to help
                you boost good health and improve the overall Well-being. We
                believe that the right nutrition can make a difference in your
                life, regardless of your motivation.
              </p>
              <button className="home-btn">More About Us</button>
            </div>
            <div className="col-lg-6 home-img-container">
              <Image src={home1} alt="" fluid className="banner-img" />
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}
export default Banner;
