import "./product.css";
function Cta() {
  return (
    <>
      <section style={{ marginTop: "40px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 ">
              <div className="cta-div1">
                <h3>Distributor</h3>
                <p>Cursus sodales bibendum cubilia curabitur porta</p>
                <p style={{ display: "flex", justifyContent: "center" }}>
                  <button>Join Now</button>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cta-div2">
                <h3>Call Center</h3>
                <p>Cursus sodales bibendum cubilia curabitur porta</p>
                <p>
                  <button>Contact</button>{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cta-div3">
                <h3>Talk About Products</h3>
                <p>Cursus sodales bibendum cubilia curabitur porta</p>
                <p>
                  <button>Read More</button>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Cta;
