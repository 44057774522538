import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
function Business() {
  return (
    <>
      <section style={{ backgroundColor: "white", marginTop: "60px" }}>
        <div
          className="container"
          style={{ paddingTop: "100px", paddingBottom: "100px" }}
        >
          <div className="row">
            {/* <div>
              <h1 style={{ display: "flex", justifyContent: "center" }}>
                The Best Guarantee In{" "}
              </h1>
              <h1 style={{ display: "flex", justifyContent: "center" }}>
                The Business
              </h1>
              <p style={{ display: "flex", justifyContent: "center" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis.
              </p>
            </div> */}
            <span style={{ marginTop: "10px" }} />
            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                style={{
                  backgroundColor: "darkgreen",
                  color: "lightgreen",
                  width: "80%",
                  height: "90px",
                  border: "none",
                  borderRadius: "15px",
                  fontSize: "3vh",
                  marginTop: "30px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "white", paddingRight: "10px" }}
                  size="2x"
                />
                Clinically Proven, Science-Backed Formulations
              </button>
            </div>
            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                style={{
                  backgroundColor: "darkgreen",
                  color: "lightgreen",
                  width: "80%",
                  height: "90px",
                  border: "none",
                  borderRadius: "15px",
                  fontSize: "3vh",
                  marginTop: "30px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "white", paddingRight: "10px" }}
                  size="2x"
                />
                Natural, High-Quality Ingredients with Targeted Benefits
              </button>
            </div>
            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                style={{
                  backgroundColor: "darkgreen",
                  color: "lightgreen",
                  width: "80%",
                  height: "90px",
                  border: "none",
                  borderRadius: "15px",
                  fontSize: "3vh",
                  marginTop: "30px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "white", paddingRight: "10px" }}
                  size="2x"
                />
                Easy Integration with Medical Nutrition Therapy
              </button>
            </div>
            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                style={{
                  backgroundColor: "darkgreen",
                  color: "lightgreen",
                  width: "80%",
                  height: "90px",
                  border: "none",
                  borderRadius: "15px",
                  fontSize: "3vh",
                  marginTop: "30px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "white", paddingRight: "10px" }}
                  size="2x"
                />
                High-Quality, Natural Ingredients for Safe, Long-Term Use
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Business;
