import { Image } from "react-bootstrap";
import mv from "../images/about-us/mv.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faEye } from "@fortawesome/free-solid-svg-icons";

function MissionAndVision() {
  return (
    <>
      <section
        style={{
          marginTop: "60px",
          marginBottom: "100px",
        }}
      >
        <div className="container">
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "50px",
              paddingBottom: "50px",
              fontWeight: "600",
              color: "green",
            }}
          >
            Our Mission & Vision
          </h1>
          <div className="row">
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image src={mv} alt="" fluid style={{ borderRadius: "15px" }} />
            </div>
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "70px",
                        height: "60px",
                        marginRight: "15px",
                        backgroundColor: "lightgray",
                        borderRadius: "15px",
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} size="xl" />
                    </p>
                  </div>
                  <div>
                    <h2>Our Vision</h2>
                    <p>
                      Transforming Lives through Cutting Edge Medical Nutrition.
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "70px",
                        height: "60px",
                        marginRight: "15px",
                        backgroundColor: "lightgray",
                        borderRadius: "15px",
                      }}
                    >
                      <FontAwesomeIcon icon={faBullseye} size="xl" />
                    </p>
                  </div>
                  <div>
                    <h2>Our Mission</h2>
                    <p>
                      Delivering Transformative, evidence-based nutritional
                      solutions by pioneering advancements in Medical Nutrition
                      Therapy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-lg-6"
              style={{
                backgroundImage: `url(https://dummyjson.com/image/500x400)`,

                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                height: "400px",
                maxWidth: "500px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div
                style={{
                  width: "70%",
                  backgroundColor: "skyblue",
                  padding: "10px",
                  marginLeft: "-12px",
                }}
              >
                <h4>Mission</h4>
                <p>
                  Delivering Transformative, evidence-based nutritional
                  solutions by pioneering advancements in Medical Nutrition
                  Therapy.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6"
              style={{
                backgroundImage: `url(https://dummyjson.com/image/500x400)`,
                height: "400px",
                width: "500px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                marginTop: "125px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div
                style={{
                  width: "70%",
                  backgroundColor: "skyblue",
                  padding: "10px",
                  marginLeft: "-12px",
                }}
              >
                <h4>Vision</h4>
                <p>
                  Transforming Lives through Cutting -Edge Medical Nutrition
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
export default MissionAndVision;
