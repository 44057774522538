import { Image } from "react-bootstrap";
import "./products.css";
import pro1 from "../../images/home/1.png";
import pro2 from "../../images/home/2.png";
import pro3 from "../../images/home/3.png";

function Products() {
  return (
    <>
      <section>
        <div className="container product-img">
          <div className="row ">
            <h1 className="products-header">Our Products</h1>
            <div className="col-lg-4">
              <Image src={pro1} alt="" fluid />
            </div>

            <div className="col-lg-4">
              <Image src={pro3} alt="" fluid />
            </div>

            <div className="col-lg-4">
              <Image src={pro2} alt="" fluid />
            </div>
          </div>

          {/* <div className="row">
            <div className="col-lg-4">
              <Image src="https://dummyjson.com/image/500x550" alt="" fluid />
            </div>

            <div className="col-lg-4">
              <Image src="https://dummyjson.com/image/500x550" alt="" fluid />
            </div>

            <div className="col-lg-4">
              <Image src="https://dummyjson.com/image/500x550" alt="" fluid />
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
export default Products;
