import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../images/logo/logo.png";
import "./header.css";

function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Container>
        <Navbar.Brand href="/home">
          <img src={logo} alt="" className="logo-1 " />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ paddingLeft: "20px" }}>
            <span className="menu"></span>
            <Nav.Link href="/home">
              <sapn className="nav-menu-item">HOME</sapn>
            </Nav.Link>
            <NavDropdown
              title="OUR PRODUCTS"
              id="basic-nav-dropdown"
              className="nav-menu-item"
            >
              <NavDropdown.Item href="saansure">Saansure</NavDropdown.Item>
              <NavDropdown.Item href="obeat">Obeat</NavDropdown.Item>
              <NavDropdown.Item href="cogni-strong">
                Cogni Strong
              </NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              {/* <NavDropdown.Item href="product4">PRODUCT 4</NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link href="about-us">
              <sapn className="nav-menu-item">ABOUT US</sapn>
            </Nav.Link>

            <Nav.Link href="contact">
              <span className="nav-menu-item">CONTACT</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
