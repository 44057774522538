import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import testmonials from "../../images/home/testmonials.png";
// https://dummyjson.com/image/500x400
function Testmonials() {
  return (
    <section style={{ marginTop: "50px", marginBottom: "50px" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Image src={testmonials} alt="" fluid />
            <button
              style={{
                border: "none",
                width: "200px",
                height: "60px",
                fontSize: "4vh",
                backgroundColor: "lightgreen",
                marginTop: "-30px",
              }}
              disabled
            >
              Testmonials
            </button>
          </div>
          <div className="col-lg-6">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Carousel data-bs-theme="dark" controls={false}>
                <Carousel.Item>
                  <div
                    className="row"
                    style={{ paddingTop: "75px", paddingBottom: "75px" }}
                  >
                    <div className="col-lg-8">
                      The porridge is fantastic. I cooked myself and i am going
                      to make creative dishes out of it. It's so unique and the
                      blend is so good. I will try to add more things and
                      enhance it like a complete meal by itself.
                      <br /> <br />
                      <span style={{ fontWeight: "600" }}>
                        - Dr . Siva from Singapore
                      </span>
                    </div>
                    <div className="col-lg-4">
                      <Image
                        src="https://dummyjson.com/image/300x300"
                        alt=""
                        roundedCircle
                        fluid
                      />
                    </div>
                  </div>
                </Carousel.Item>
                {/* <Carousel.Item>
                  <div
                    className="row"
                    style={{ paddingTop: "75px", paddingBottom: "75px" }}
                  >
                    <div className="col-lg-8">
                      Lorem ipsum dolor sit amet consectetur. Morbi cursus quis
                      sollicitudin a dolor odio diam risus pretium. Lacus
                      elementum lacus viverra tellus amet.Lorem ipsum dolor sit
                      amet consectetur. Morbi cursus quis sollicitudin a dolor
                      odio diam risus pretium. Lacus elementum lacus viverra
                      tellus amet.
                    </div>
                    <div className="col-lg-4">
                      <Image
                        src="https://dummyjson.com/image/300x300"
                        alt=""
                        roundedCircle
                        fluid
                      />
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div
                    className="row"
                    style={{ paddingTop: "75px", paddingBottom: "75px" }}
                  >
                    <div className="col-lg-8">
                      Lorem ipsum dolor sit amet consectetur. Morbi cursus quis
                      sollicitudin a dolor odio diam risus pretium. Lacus
                      elementum lacus viverra tellus amet.Lorem ipsum dolor sit
                      amet consectetur. Morbi cursus quis sollicitudin a dolor
                      odio diam risus pretium. Lacus elementum lacus viverra
                      tellus amet.
                    </div>
                    <div className="col-lg-4">
                      <Image
                        src="https://dummyjson.com/image/300x300"
                        alt=""
                        roundedCircle
                        fluid
                      />
                    </div>
                  </div>
                </Carousel.Item> */}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testmonials;
