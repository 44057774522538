import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import video from "../../images/video/home-video.mp4";

const FullScreenVideo = () => {
  const videoRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const videoElement = videoRef.current;

    // Function to handle video end and redirect to homepage
    const handleVideoEnd = () => {
      history.push("/home");
    };

    // Play the video and attach the ended event listener
    videoElement.play().catch((error) => {
      console.error("Autoplay failed:", error);
    });
    videoElement.addEventListener("ended", handleVideoEnd);

    // Cleanup event listener on component unmount
    return () => {
      videoElement.removeEventListener("ended", handleVideoEnd);
    };
  }, [history]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: 9999,
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        muted
        loop={false} // Ensure the video does not loop
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        playsInline
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default FullScreenVideo;
