import { Image } from "react-bootstrap";
import about from "../images/about-us/about.png";

function Strengths() {
  return (
    <>
      <section style={{ marginTop: "125px", marginBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <Image src={about} alt="" fluid />
            </div>
            <div
              className="col-lg-7"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "20px",
              }}
            >
              <div>
                {/* <p
                  style={{
                    color: "green",
                    fontWeight: "600",
                    marginBottom: "0px",
                  }}
                >
                  STRENGTHS
                </p> */}
                <h1>Nutrition for your well-being</h1>
                <p>
                  At Saanso Nutri Sciences, we are dedicated to creating
                  innovative health solutions that make a real difference. Our
                  mission is simple: to bring you products that combine nature’s
                  finest ingredients with scientific research to support your
                  health in the most effective and natural way possible. Our
                  Products are specialised to meet the needs of chronic health
                  conditions, like diabetes, cognitive health, and weight
                  management.
                </p>
                <p>
                  We are dedicated to creating innovative health solutions that
                  make a real difference. Our mission is simple: to bring you
                  products that combine nature’s finest ingredients with
                  scientific research to support your health in the most
                  effective and natural way possible. Our Products are
                  specialised to meet the needs of chronic health conditions,
                  like diabetes, cognitive health, and weight management.
                </p>
                {/* <a>
                  <button
                    style={{
                      width: "150px",
                      height: "42px",
                      border: "none",
                      color: "white",
                      backgroundColor: "lightgreen",
                      borderRadius: "20px",
                    }}
                  >
                    Know More
                  </button>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Strengths;
