import Header from "./Header/Header";
import Cta from "./Products/Cta";
import ProductBanner from "./Products/ProductBanner";
import ProductDescription from "./Products/ProductDescription";
// import ProductIndication from "./Products/ProductIndication";
import RelatedProducts from "./Products/RelatedProducts";
import "./App.css";
import Footer from "./Footer/Footer";

import { Image } from "react-bootstrap";
import "./Homepage/NutritionBenefits/nutrition.css";
import Accordion from "react-bootstrap/Accordion";
import productimg from "./images/ProductBanners/product3.png";
import cogni from "./images/cogni/cogni.png";
import brain from "./images/cogni/brain.png";
import cogniv from "./images/cogni/cogniv.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import videobanner from "./images/cogni/Congni.png";
import pimg1 from "./images/home/1.png";
import pimg2 from "./images/home/3.png";

const content = {
  bhead:
    " A Powerful Superfood Blend for Cognitive Health and Overall Wellness",
  bimg: productimg,
};

const pdcontent = {
  pdimg: cogni,
  pdh1: "Cogni Strong",
  pdh12: "Elevate Your Mind and Wellness Naturally",
  pdpara1:
    "Cogni Strong is a nutrient-packed blend of superfoods crafted to support brain health, boost memory, and enhance focus. Made with a rich mix of antioxidant-dense ingredients like leafy greens, roots, seeds, and nuts, Cogni Strong is designed to help you stay mentally sharp and energised throughout the day. This easy-to-integrate supplement offers the power of nature in each serving, giving you a simple way to support cognitive wellness and overall vitality.",
  pdpara2: "",
  pdcomp: [
    "Millets & Cereals 36% (Pearl Barley,Ragi,Jowar),Dry fruits, Nuts & Seeds 32%(Almonds, Cashews, Raisins, Dates, Chia seeds, Flax seeds, Water melon, Pumpkin seeds, Sunflower seeds )Pulses 12 %(Bengal gram, Black gram,Green gram), Tamarind, Dehydrated Vegetables 8%(Carrot,Beetroot,Spinach,Moringa, Onion), Vegan omega 3 fatty acids,  Turmeric, Jaggery   Rock Salt, Jaggery,Amla, Groundnut oil, Gingelly oil, Ginger, Garlic, Chillies.",
    "CONTAINS ADDED NATURAL FLAVOUR (MIXED MASALA).",
  ],
};

// const pdIndications = {
//   t1: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t2: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t3: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t4: "Ornare vestibulum aenean nullam nulla pretium tellus.",
// };
const relatedimages = {
  img1: pimg1,
  img2: pimg2,
  img3: "https://dummyjson.com/image/500x550",
};

function Product3() {
  return (
    <>
      <Header />
      <ProductBanner content={content} />
      <ProductDescription pdcontent={pdcontent} />
      {/* <ProductIndication indications={pdIndications} /> */}
      <section style={{ marginTop: "75px", marginBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <p
                  style={{
                    fontSize: "0.75rem",
                    color: "lightgreen",
                    marginBottom: "0px",
                    fontWeight: "600",
                  }}
                >
                  Key Benefits of Cogni Strong
                </p>
                <h2>Supports Memory and Focus</h2>
                <p>
                  Packed with brain-boosting nutrients that help sharpen memory
                  and improve concentration, Cogni Strong is designed for those
                  who want to stay mentally sharp.
                </p>
                <h2>Rich in Antioxidants for Brain Health</h2>
                <p>
                  Antioxidant-rich ingredients like turmeric, amla, and leafy
                  greens protect brain cells, helping reduce the effects of
                  ageing and maintain long-term brain health.
                </p>
                <h2>Sustained Natural Energy</h2>
                <p>
                  With a balanced blend of greens, seeds, and root vegetables,
                  Cogni Strong provides steady energy throughout the day without
                  the need for stimulants.
                </p>
                <h2>Enhances Overall Well-being</h2>
                <p>
                  Beyond cognitive support, Cogni Strong is packed with
                  nutrients that support immune health, energy levels, and
                  overall vitality.
                </p>
                {/* <a href="#">
                  <button
                    style={{
                      width: "150px",
                      height: "40px",
                      border: "none",
                      borderRadius: "20px",
                      backgroundColor: "lightgreen",
                      color: "white",
                    }}
                  >
                    Know More
                  </button>
                </a> */}
              </div>
            </div>
            <div className="col-lg-6">
              <Image src={brain} alt="" fluid />
            </div>
          </div>
        </div>
      </section>
      <section style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "6vh",
            color: "rgb(108, 184, 255)",
            fontWeight: "600",
          }}
        >
          All about products
        </h1>

        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "6vh",
            color: "rgb(108, 184, 255)",
            fontWeight: "600",
          }}
        >
          is nutrition
        </h1>
        <span style={{ paddingTop: "40px" }} />
        <div className="container">
          <div className="row ">
            <div className="col-lg-3 nut-sec-1">
              <div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Omega 3 with DHA for Sharpness</h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia{" "}
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Builds & Repairs Muscles With Natural Protein
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Natural Vitamin C & Antioxidants for Brain Protection
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <h2 className="nutri-p">
                  Anti Inflammatory power
                  <br /> for mental clarity{" "}
                </h2>
                {/* <p className="nutri-p">
                  Ridiculus sceleque phasellus interdum potenti velitcubilia
                </p> */}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Image
                  src={cogniv}
                  alt=""
                  fluid
                  style={{ display: "flex", justifyContent: "center" }}
                />
              </div>
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <h2 className="nutri-p">Fibre for Peaceful Gut</h2>
                {/* <p className="nutri-p">
                  Ridiculus sceleque phasellus interdum potenti velitcubilia
                </p> */}
              </div>
            </div>
            <div className="col-lg-3 nut-sec-1">
              <div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Natural Vitamin & Minerals For Cognitive Function
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia{" "}
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Need text here</h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Need text here</h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="container"
          style={{
            marginTop: "75px",
            marginBottom: "75px",
          }}
        >
          <div className="row">
            <div className="col-lg-12">
              <h2>Usage and Dosage Instructions</h2>
              <p>
                Mix one sachet of Cogni Strong into smoothies, yogourt, or your
                morning oats. Enjoy as a breakfast supplement or as an afternoon
                boost.
              </p>
              <p>
                Cogni Strong is versatile enough to add to your favourite meals,
                or it can be enjoyed with warm milk or buttermilk for a
                comforting and nutritious addition to your daily routine.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div classname="container">
          <div
            className="row"
            style={{
              padding: "15px",
              marginLeft: "10px",
              marginRight: "10px",
              height: "450px",
              backgroundImage: `url(${videobanner})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  console.log("hello");
                }}
              >
                <FontAwesomeIcon
                  icon={faPlay}
                  size="3x"
                  style={{ marginLeft: "7px" }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>

      <RelatedProducts relatedimgs={relatedimages} />

      <section>
        <div className="container">
          <div className="row">
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "60px",
                paddingBottom: "60px",
              }}
            >
              Frequently Asked Questions
            </h1>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>1.When to consume? </Accordion.Header>
                <Accordion.Body>
                  This can be consumed like breakfast or as partial meal
                  replacement during dinner.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2.How does antioxidant & protein food help?
                </Accordion.Header>
                <Accordion.Body>
                  Antioxidant rich foods helps in protecting brain cells from
                  free radical damage and proteins help in the production of
                  necessary neurotransmitters for brain health.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3. How does foods like Cognistrong help?
                </Accordion.Header>
                <Accordion.Body>
                  Cognistrong is a nutrient dense food and has high value
                  calorie when compared to regular diet and also helps in brain
                  health because of its historically proven antioxidants &
                  proteins.{" "}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
      {/* <Cta /> */}
      <Footer />
    </>
  );
}
export default Product3;
