import MissionAndVision from "./AboutUs/MandV";
import Strengths from "./AboutUs/Strengths";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
// import ProductBanner from "./Products/ProductBanner";
import img3d from "./images/about-us/3d.png";
import Business from "./Homepage/Business/Business";
import WhySaanso from "./AboutUs/WhySaanso";
import { Image } from "react-bootstrap";
import bannerimg from "./images/about-us/aboutbanner.png";
import story from "./images/about-us/story.png";

// const content = {
//   bhead: "Empowering Health Through Nature",
//   bimg: img3d,
// };

function AboutUs() {
  return (
    <>
      <Header />
      {/* <ProductBanner content={content} /> */}
      <section
        style={{
          backgroundImage: `url(${bannerimg})`,
          opacity: "0.7",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row" style={{ height: "500px" }}>
            <div
              className="col-lg-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h1
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontSize: "3.5rem",
                  }}
                >
                  About Us
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Strengths />
      <MissionAndVision />
      <section
        style={{
          marginTop: "75px",
          marginBottom: "60px",
          backgroundColor: "black",
          paddingTop: "45px",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
              }}
            >
              <div>
                <h1 style={{ color: "white", fontSize: "4rem" }}>Our Story</h1>
                <p style={{ color: "white" }}>
                  Our story began with a vision to transform health and wellness
                  through scientifically-backed nutrition, focusing on diabetes,
                  obesity, and cognitive health. With a deep commitment to
                  Medical Nutrition Therapy (MNT). Our products are thoughtfully
                  designed to support both consumers seeking proactive health
                  solutions and healthcare professionals dedicated to patient
                  care. Guided by rigorous research and a dedication to
                  transparent, evidence-based benefits, we are here to make a
                  real difference, helping people move towards healthier and
                  brighter futures.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Image src={story} alt="" fluid />
            </div>
          </div>
        </div>
      </section>
      <Business />
      <WhySaanso />
      <Footer />
    </>
  );
}
export default AboutUs;
