import { Image } from "react-bootstrap";
import "./features.css";
import pro1 from "../../images/home/1.png";
import pro2 from "../../images/home/2.png";
import pro3 from "../../images/home/3.png";
import few1 from "../../images/home/2001.png";
import few2 from "../../images/home/2401.png";
import few3 from "../../images/home/2402.png";
import few4 from "../../images/home/2002.png";

function Features() {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div
                  className="col-lg-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <Image src={few1} alt="" fluid className="featnew-img" />
                  </div>
                </div>
                <div
                  className="col-lg-6"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <Image src={few2} alt="" fluid className="featnew-img" />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "30px" }}>
                <div
                  className="col-lg-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "start",
                  }}
                >
                  <div>
                    <Image src={few3} alt="" fluid className="featnew-img" />
                  </div>
                </div>
                <div
                  className="col-lg-6"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div>
                    <Image src={few4} alt="" fluid className="featnew-img" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <p
                  style={{
                    display: "flex",
                    padding: "15px",
                  }}
                >
                  <span
                    style={{
                      height: "30px",
                      width: "150px",
                      backgroundColor: "lightgreen",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "15px",
                    }}
                  >
                    Any Heading Here
                  </span>
                </p>
                <h3 style={{ paddingLeft: "20px", paddingBottom: "15px" }}>
                  Transform Your Health
                </h3>
                <p
                  style={{
                    paddingLeft: "20px",
                    paddingBottom: "20px",
                    fontSize: "1.2rem",
                  }}
                >
                  Saanso Nutri Sciences makes wellness simple with natural,
                  science-backed products that fit effortlessly into your daily
                  routine. Enjoy real health benefits, one easy step at a time.
                </p>
                <a href="/about-us">
                  <button
                    style={{
                      border: "none",
                      width: "140px",
                      height: "50px",
                      marginLeft: "20px",
                      backgroundColor: "lightseagreen",
                      color: "white",
                      fontSize: "1.1rem",
                      borderRadius: "25px",
                      marginBottom: "30px",
                    }}
                  >
                    About Us
                  </button>
                </a>
              </div>
            </div>
            {/* <div className="col-lg-3">
              <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <h1 className="features-h1">Product Features</h1>
                <p>
                  Saanso Nutri Sciences makes wellness simple with natural,
                  science-backed products that fit effortlessly into your daily
                  routine. Enjoy real health benefits, one easy step at a time
                </p>
              </div>
            </div>
            <div className="col-lg-3 features">
              <div>
                <Image src="https://dummyjson.com/image/270x306" alt="" fluid />
                <p>Health Benefits in Every Serving</p>
              </div>
            </div>
            <div className="col-lg-3 features">
              <div>
                <Image src="https://dummyjson.com/image/270x306" alt="" fluid />
                <p>Quick and Easy to Enjoy Anytime</p>
              </div>
            </div>
            <div className="col-lg-3 features">
              <div>
                <Image src="https://dummyjson.com/image/270x306" alt="" fluid />
                <p> Nutrition for All-Day Energy</p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
export default Features;
