import { Image } from "react-bootstrap";
import "./product.css";
function RelatedProducts({ relatedimgs }) {
  return (
    <>
      <section style={{ paddingTop: "50px", paddingBottom: "60px" }}>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "50px",
            color: "green",
            fontWeight: "600",
          }}
        >
          OTHER PRODUCTS
        </h1>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Image
                src={relatedimgs.img1}
                alt=""
                fluid
                style={{ marginTop: "17px" }}
              />
            </div>
            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Image
                src={relatedimgs.img2}
                alt=""
                fluid
                style={{ marginTop: "17px" }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default RelatedProducts;
