import { Image } from "react-bootstrap";
import "./product.css";
import banner from "../images/about-us/banner.png";
function ProductBanner({ content }) {
  return (
    <>
      <section style={{ backgroundImage: `url(${banner})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 phead">
              <h2>{content.bhead}</h2>
            </div>
            <div className="col-lg-7">
              <Image src={content.bimg} alt="" fluid className="pbimg" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ProductBanner;
