import "./contact.css";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "react-bootstrap";
import banner from "../images/contactbanner.png";
function ContactPage() {
  return (
    <>
      <Image src={banner} alt="" fluid />
      <section style={{ marginTop: "60px", marginBottom: "60px" }}>
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-4">
              <h5 className="c-heading">
                Product & Services and adverse event reporting.
              </h5>
            </div>
            <div className="col-lg-8">
              <p style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                Lorem ipsum dolor sit amet consectetur. Morbi cursus quis
                sollicitudin a dolor odio diam risus pretium. Lacus elementum
                lacus viverra tellus amet.Lorem ipsum dolor sit amet
                consectetur. Morbi cursus quis sollicitudin a dolor odio diam
                risus pretium. Lacus elementum lacus viverra tellus amet.
              </p>
            </div>
          </div> */}
          <div className="row" style={{ marginTop: "75px" }}>
            {/* <div
              className="col-lg-4"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  backgroundColor: "lightgreen",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "25px",
                }}
              >
                <FontAwesomeIcon icon={faPhone} size="2x" />
              </p>
              <div>
                <h4>Phone</h4>
                <p>+91 9999999999</p>
              </div>
            </div>
            <div
              className="col-lg-4"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  backgroundColor: "lightgreen",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "25px",
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </p>
              <div>
                <h4>Email</h4>
                <p>mail@gmail.com</p>
              </div>
            </div> */}
            <div
              className="col-lg-4"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  backgroundColor: "lightgreen",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "25px",
                }}
              >
                <FontAwesomeIcon icon={faLocationDot} size="2x" />
              </p>
              <div>
                <h4>Location</h4>
                <p>Cyber towers,Hyderabad. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ContactPage;
