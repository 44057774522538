import ContactPage from "./Contact/ContactPage";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

function Contact() {
  return (
    <>
      <Header />
      <ContactPage />
      <Footer />
    </>
  );
}
export default Contact;
