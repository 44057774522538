import { Image } from "react-bootstrap";
import whysaanso from "../images/about-us/whysaanso.png";

function WhySaanso() {
  return (
    <>
      <section style={{ marginTop: "75px" }}>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10px",
            paddingBottom: "50px",
            fontWeight: "600",
            color: "green",
          }}
        >
          Why Saanso Nutri Sciences
        </h1>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Image
                src={whysaanso}
                alt=""
                fluid
                style={{ borderRadius: "15px" }}
              />
            </div>
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "30px",
              }}
            >
              <div>
                <p style={{ color: "skyblue", fontWeight: "600" }}>
                  Saanso Nutri Sciences
                </p>
                <h2>
                  Innovative, Research-Based Products for Lasting Health
                  Benefits
                </h2>
                <p>
                  At Saanso Nutri Sciences, we combine nature and science to
                  create products that help manage health challenges like blood
                  sugar, brain health, and weight. Each product uses natural
                  ingredients, guided by research, to provide effective,
                  long-term support for your health.
                </p>
                <p>
                  Our products are clinically tested and trusted by doctors for
                  their effectiveness. Our formulations are proven to support
                  blood sugar control, improve brain health, and help with
                  weight management, making them a reliable choice for health
                  professionals and their patients.
                </p>
                <p>
                  Our products are designed to fit easily into your daily
                  routine—just add to meals, snacks, or beverages. Each product
                  supports specific health goals, making it simple to stay on
                  track with a natural approach that works.
                </p>
                {/* <a>
                  <button
                    style={{
                      backgroundColor: "skyblue",
                      border: "none",
                      borderRadius: "3px",
                      width: "130px",
                      height: "40px",
                    }}
                  >
                    Read More
                  </button>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WhySaanso;
