import Header from "./Header/Header";
import Cta from "./Products/Cta";
import ProductBanner from "./Products/ProductBanner";
import ProductDescription from "./Products/ProductDescription";
// import ProductIndication from "./Products/ProductIndication";
import RelatedProducts from "./Products/RelatedProducts";
import "./App.css";
import Footer from "./Footer/Footer";
import { Image } from "react-bootstrap";
import "./Homepage/NutritionBenefits/nutrition.css";
import Accordion from "react-bootstrap/Accordion";
import productimg from "./images/ProductBanners/product1.png";
import sansure from "./images/sansure/sansure.png";
import diet from "./images/sansure/diet.png";
import sansurev from "./images/sansure/sansurev.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import videobanner from "./images/sansure/Saansure.png";
import pimg1 from "./images/home/3.png";
import pimg2 from "./images/home/2.png";

const content = {
  bhead: "World's first clinically proven MNT for Blood sugar management",
  bimg: productimg,
};

const pdcontent = {
  pdimg: sansure,
  pdh1: "Saansure",
  pdh12: "Natural Blood Sugar Balance with every spoon",
  pdpara1:
    "Saansure is more than just a nutritional supplement; it’s a daily ally for those aiming to manage their blood sugar naturally and effectively. Developed with carefully chosen ingredients that support balanced blood sugar, sustained energy, and overall wellness, Saansure is crafted to help you take control of your health through natural, science-backed nutrition. With Saansure, you're not just managing blood sugar—you’re choosing a healthier lifestyle.",
  pdpara2: "",
  pdcomp: [
    "Millets and cereals 45%(Pearl Barley, Pearl Millet, Ragi, Jowar), Pulses 15% (Bengal gram, Black gram,Green gram), Nuts & seeds 19 %(Almonds,Cashew, Raisins, Chia seeds, Flax seeds, Water melon seeds, Sunflowerseeds, Pumpkinseeds), Dehydrated Vegetables 2% (Carrot & Tomato) Jaggery, Rock Salt, Tamarind, Gingelly oil, Groundnut oil, Ginger, Garlic, Chillies.",
    "CONTAINS ADDED NATURAL FLAVOUR (MIXED MASALA).",
  ],
};

// const pdIndications = {
//   t1: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t2: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t3: "Ornare vestibulum aenean nullam nulla pretium tellus.",
//   t4: "Ornare vestibulum aenean nullam nulla pretium tellus.",
// };
const relatedimages = {
  img1: pimg1,
  img2: pimg2,
  img3: "https://dummyjson.com/image/500x550",
};

function Product1() {
  return (
    <>
      <Header />
      <ProductBanner content={content} />
      <ProductDescription pdcontent={pdcontent} />
      {/* <ProductIndication indications={pdIndications} /> */}
      <section style={{ marginTop: "75px", marginBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <p
                  style={{
                    fontSize: "0.75rem",
                    color: "lightgreen",
                    marginBottom: "0px",
                    fontWeight: "600",
                  }}
                >
                  Key Benefits of Saansure
                </p>
                <h2>Steady Blood Sugar Levels</h2>
                <p>
                  By including fibre-rich whole grains and low-glycemic
                  ingredients, Saansure helps prevent spikes and crashes in
                  blood sugar, providing steady energy throughout the day.
                </p>
                <h2>Supports Weight Control</h2>
                <p>
                  High in fibre, Saansure helps you feel fuller for longer,
                  naturally reducing cravings and supporting a healthy weight.
                </p>
                <h2>Heart and Digestive Health</h2>
                <p>
                  Packed with omega fats, fibre, and antioxidants, Saansure
                  promotes heart health and supports digestion for a
                  well-rounded approach to wellness.
                </p>

                {/* <a href="#">
                  <button
                    style={{
                      width: "150px",
                      height: "40px",
                      border: "none",
                      borderRadius: "20px",
                      backgroundColor: "lightgreen",
                      color: "white",
                    }}
                  >
                    Know More
                  </button>
                </a> */}
              </div>
            </div>
            <div className="col-lg-6">
              <Image src={diet} alt="" fluid />
            </div>
          </div>
        </div>
      </section>
      <section style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "6vh",
            color: "rgb(108, 184, 255)",
            fontWeight: "600",
          }}
        >
          All about products
        </h1>

        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "6vh",
            color: "rgb(108, 184, 255)",
            fontWeight: "600",
          }}
        >
          is nutrition
        </h1>
        <span style={{ paddingTop: "40px" }} />
        <div className="container">
          <div className="row ">
            <div className="col-lg-3 nut-sec-1">
              <div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Balances Blood Sugar Naturally </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia{" "}
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">
                    Steady Energy Without sugar Spikes
                  </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Helps to Manage Blood Sugar</h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <h2 className="nutri-p">
                  Natural omega - 3<br /> fats for cell repair{" "}
                </h2>
                {/* <p className="nutri-p">
                  Ridiculus sceleque phasellus interdum potenti velitcubilia
                </p> */}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Image
                  src={sansurev}
                  alt=""
                  fluid
                  style={{ display: "flex", justifyContent: "center" }}
                />
              </div>
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <h2 className="nutri-p">
                  Gut-Friendly Fiber <br />
                  for Smooth Digestion
                </h2>
                {/* <p className="nutri-p">
                  Ridiculus sceleque phasellus interdum potenti velitcubilia
                </p> */}
              </div>
            </div>
            <div className="col-lg-3 nut-sec-1">
              <div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Improves Insulin sensitization</h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia{" "}
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Helps in Insulin Production </h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <h2 className="nutri-p">Fuel Your Day, the Natural Way</h2>
                  {/* <p className="nutri-p">
                    Ridiculus sceleque phasellus interdum potenti velitcubilia
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="container"
          style={{
            marginTop: "75px",
            marginBottom: "75px",
          }}
        >
          <div className="row">
            <div className="col-lg-12">
              <h2>Usage and Dosage Instructions</h2>
              <p>
                One sachet a day as per the instructions on the sachet as
                partial meal replacement
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div classname="container">
          <div
            className="row"
            style={{
              padding: "15px",
              marginLeft: "10px",
              marginRight: "10px",
              height: "450px",
              backgroundImage: `url(${videobanner})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  console.log("hello");
                }}
              >
                <FontAwesomeIcon
                  icon={faPlay}
                  size="3x"
                  style={{ marginLeft: "7px" }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <RelatedProducts relatedimgs={relatedimages} />
      <section>
        <div className="container">
          <div className="row">
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "60px",
                paddingBottom: "60px",
              }}
            >
              Frequently Asked Questions
            </h1>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  1.Can pre diabetics consume?{" "}
                </Accordion.Header>
                <Accordion.Body>Yes.</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>2.Can diabetics consume?</Accordion.Header>
                <Accordion.Body>Yes with physicians guidance.</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  3.Why Physicians' guidance?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Any modification in diet impacts blood sugar levels & hence we
                  suggest taking guidance for tapering of medicines.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>4.When to consume? </Accordion.Header>
                <Accordion.Body>
                  This can be consumed like breakfast or as partial meal
                  replacement during dinner.{" "}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>

      {/* <Cta /> */}
      <Footer />
    </>
  );
}
export default Product1;
